<template>
  <BaseDropdown
    v-bind="$attrs"
    v-model="countryName"
    :options="countries"
    :loading="isLoading"
    label="hac.countries.label"
    optionLabel="displayName"
    optionValue="displayName"
    floatingLabel
    :placeholder="null"
  />
</template>

<script>
import { watch, ref } from "vue";
import useApi from "@use/useApi";

export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const countryName = ref(props.modelValue);
    const countries = ref([]);

    const { isLoading, docs } = useApi({
      module: "hac",
      method: "listCountries",
      onMount: true,
      onSuccess: () => {
        countries.value = docs.value.data;
      },
    });

    watch(countryName, (value) => {
      emit("update:modelValue", value);
    });

    return {
      countryName,
      countries,
      isLoading,
    };
  },
};
</script>
