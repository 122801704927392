<template>
  <div>
    <BaseDivider />
    <BaseRow class="my-3 px-3">
      <BaseCol cols="12" sm="6">
        <BaseHeadline size="5" fake="5" :noMargin="true">
          {{ $t("uc.cart.price") }}
        </BaseHeadline>
      </BaseCol>
      <BaseCol cols="12" sm="6">
        <BaseHeadline size="5" fake="5" class="text-primary text-right" :noMargin="true">
          {{ getPriceLabel(cart.price) }}
        </BaseHeadline>
      </BaseCol>
    </BaseRow>
    <BaseDivider />
  </div>
</template>

<script>
import { getPriceLabel } from "@/modules/user-course-uc/helper/GetPriceLabel";

export default {
  name: "CartPrice",
  props: {
    cart: {
      type: Object,
      required: true,
      default: () => ({
        price: null,
      }),
    },
  },
  setup() {
    return {
      getPriceLabel,
    };
  },
};
</script>
