<template>
  <BaseFrontdoor>
    <BaseContainer fluid class="max-w-1024">
      <BaseHeadline size="1" fake="2" class="mt-4 mb-6">
        {{ $t("uc.checkout.title") }}
      </BaseHeadline>

      <div v-if="isLoading" class="text-center py-4">
        <BaseProgressSpinner />
      </div>

      <div v-else>
        <BaseParagraph class="mb-6">
          {{ $t("uc.checkout.description") }}
        </BaseParagraph>

        <ProfileForm :form="form" @form="setFormData" :backend-validation="backendValidation" />

        <CartPrice :cart="cart" />

        <div class="flex justify-content-end">
          <BaseButton
            label="uc.checkout.button"
            @click="submit"
            :disabled="!canSubmit"
            :loading="isSubmitting"
          />
        </div>
      </div>
    </BaseContainer>
  </BaseFrontdoor>
</template>

<script>
import CartPrice from "../partials/CartPrice";
import { ref } from "@vue/runtime-dom";
import useApi from "@use/useApi";
import ProfileForm from "./partials/ProfileForm";
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";

export default {
  components: { ProfileForm, CartPrice },
  setup() {
    const router = useRouter();

    const form = reactive({});
    const backendValidation = reactive({});

    const cart = ref({
      price: null,
    });

    const formData = ref({
      canSubmit: false,
    });

    const showCart = useApi({
      module: "uc",
      method: "showCart",
      onMount: true,
      onSuccess: () => {
        cart.value = showCart.docs.value.data;
        if (cart.value.courses.length === 0) {
          router.push({
            name: "Cart",
          });
        }
      },
    });

    const showProfile = useApi({
      module: "usm",
      method: "showProfile",
      onMount: true,
      onSuccess: () => {
        form.salutation = showProfile.docs.value.data.salutation;
        form.title = showProfile.docs.value.data.title;
        form.firstName = showProfile.docs.value.data.firstName;
        form.lastName = showProfile.docs.value.data.lastName;
        form.email = showProfile.docs.value.data.email;
        form.teacher = showProfile.docs.value.data.teacher;

        if (showProfile.docs.value.data.userAddress) {
          form.street = showProfile.docs.value.data.userAddress.street;
          form.city = showProfile.docs.value.data.userAddress.city;
          form.country = showProfile.docs.value.data.userAddress.country;
          form.zipcode = showProfile.docs.value.data.userAddress.zipcode;
        }

        if (showProfile.docs.value.data.userContact) {
          form.phone = showProfile.docs.value.data.userContact.phone;
          form.mobile = showProfile.docs.value.data.userContact.mobile;
        }
      },
    });

    const updateProfile = useApi({
      module: "uc",
      method: "updateProfile",
      onSuccess: () => {
        checkout.callApi();
      },
      onError: () => {
        backendValidation.value = updateProfile.backendValidation.value;
      },
    });

    const checkout = useApi({
      module: "uc",
      method: "checkoutCart",
      onSuccess: () => {
        try {
          router.push({
            name: "CartCheckoutSuccess",
          });
        } catch {
          return;
        }
      },
    });

    const isLoading = computed(() => {
      return showCart.isLoading.value || showProfile.isLoading.value;
    });

    const canSubmit = computed(() => {
      return formData.value.canSubmit;
    });

    const isSubmitting = computed(() => {
      return updateProfile.isLoading.value || checkout.isLoading.value;
    });

    const setFormData = (form) => {
      formData.value = form;
    };

    const submit = () => {
      updateProfile.callApi(formData.value.values);
    };

    return {
      isLoading,
      cart,
      updateProfile,
      submit,
      setFormData,
      canSubmit,
      isSubmitting,
      form,
      backendValidation,
    };
  },
};
</script>
