<template>
  <BaseForm :validation="v$" ref="form">
    <BaseRow>
      <BaseCol cols="3" class="pr-3">
        <BaseTextField
          v-model="formValues.salutation"
          label="prf.update.form.label.salutation"
          :validation="v$.salutation"
          :messages="bvShowErrors('salutation').messages"
          :error="bvShowErrors('salutation').hasMessages"
          :maxLength="maxLength.USERS.SALUTATION"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.firstName"
          label="prf.update.form.label.firstName"
          :validation="v$.firstName"
          :messages="bvShowErrors('firstName').messages"
          :error="bvShowErrors('firstName').hasMessages"
          :maxLength="maxLength.USERS.FIRST_NAME"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.lastName"
          label="prf.update.form.label.lastName"
          :validation="v$.lastName"
          :messages="bvShowErrors('lastName').messages"
          :error="bvShowErrors('lastName').hasMessages"
          :maxLength="maxLength.USERS.LAST_NAME"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.street"
          label="prf.update.form.label.street"
          :validation="v$.street"
          :messages="bvShowErrors('street').messages"
          :error="bvShowErrors('street').hasMessages"
          :maxLength="maxLength.USERS.STREET"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="3">
        <BaseTextField
          v-model="formValues.zipcode"
          label="prf.update.form.label.zipcode"
          :validation="v$.zipcode"
          :messages="bvShowErrors('zipcode').messages"
          :error="bvShowErrors('zipcode').hasMessages"
          :maxLength="maxLength.USERS.ZIPCODE"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="9">
        <BaseTextField
          v-model="formValues.city"
          label="prf.update.form.label.city"
          :validation="v$.city"
          :messages="bvShowErrors('city').messages"
          :error="bvShowErrors('city').hasMessages"
          :maxLength="maxLength.USERS.CITY"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="12">
        <CountrySelect
          v-model="formValues.country"
          :validation="v$.country"
          :messages="bvShowErrors('country').messages"
          :error="bvShowErrors('country').hasMessages"
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.phone"
          label="prf.update.form.label.phone"
          :validation="v$.phone"
          :messages="bvShowErrors('phone').messages"
          :error="bvShowErrors('phone').hasMessages"
          :maxLength="maxLength.USERS.PHONE"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.mobile"
          label="prf.update.form.label.mobile"
          :validation="v$.mobile"
          :messages="bvShowErrors('mobile').messages"
          :error="bvShowErrors('mobile').hasMessages"
          :maxLength="maxLength.USERS.MOBILE"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import useBackendValidation from "@use/useBackendValidation";
import { watch } from "vue";
import CountrySelect from "@/modules/haw-components-hac/Base/CountrySelect";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";

export default {
  name: "ProfileForm",
  components: { CountrySelect },
  props: {
    backendValidation: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    form: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const rules = {
      salutation: ["required"],
      firstName: ["required"],
      lastName: ["required"],
      street: ["required"],
      zipcode: ["required"],
      city: ["required"],
      country: ["required"],
      phone: ["required"],
    };

    const { v$, formValues } = useFormValidation({
      formValues: props.form,
      rules,
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(props.backendValidation, () => {
      updateValidation(props.backendValidation.value);
    });

    const emitFormValues = () => {
      v$.value.$touch();
      if (!v$.value.$error) {
        emit("form", {
          canSubmit: true,
          values: formValues,
        });
      } else {
        emit("form", {
          canSubmit: false,
          values: formValues,
        });
      }
    };

    watch(formValues, () => {
      emitFormValues();
    });

    emitFormValues();

    return {
      v$,
      formValues,
      bvShowErrors,
      maxLength,
    };
  },
};
</script>
